//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState} from "vuex";
import utils from "@/utils";

import CertificatesModal from "@/components/documents/modals/CertificatesModal";
import SignPepModal from "@/components/documents/modals/SignPepModal";
import SignIcon from "@/components/icons/SignIcon";
import DescriptionSign from "@/components/documents/table/DescriptionSign";
import SignHistory from "@/components/documents/signHistory/SignHistory";

export default {
  name: "SignCard",
  components: {CertificatesModal, SignPepModal, SignIcon, DescriptionSign, SignHistory},
  props: {
    document: Object,
    documentType: String,
    isDocumentPage: Boolean,
    getDocuments: Function,
    changeDocument: Function,
    forceRender: Function,
    signRules: Object,
    documentDate: String,
    isExternal: Boolean,
  },
  data() {
    return {
      // showSignModal: false,
      showSignPepModal: false,
      isCertificatesModal: false,
      shown: false,
      tooltipObject: { 
        content: this.$t('sign.sign_not_allowed_by_rules'), 
        classes: ['show'], 
        placement: this.isDocumentPage ? 'bottom' : 'top',
        trigger: 'hover', 
        offset: 20,
        disabled: true,
      }
    }
  },
  created(){
    this.tooltipObject.disabled = this.canSign && this.canBeDs;
  },
  methods: {
    showCertificatesModal() {
      if(!(this.canBeDs && this.canSign)) {
        return
      }
      this.isCertificatesModal = true
      utils.addModalOpenClass()
    },
    hideCertificatesModal() {
      this.isCertificatesModal = false
      if (!this.isExternal)
        utils.removeModalOpenClass()

    },
    downloadDoc() {
      if (this.document.id) {
        utils.downloadDocument(this.document.id, this.documentType)
      }
    },
    today() {
      let today = new Date(),
      dd = String(today.getDate()).padStart(2, '0'),
      mm = String(today.getMonth() + 1).padStart(2, '0'),
      yyyy = today.getFullYear();

      return dd + '.' + mm + '.' + yyyy;
    },
    setTooltipMessage(mess) {
      this.tooltipObject.content = mess;
    },
    signPep() {
      if(this.isCertificatesModal) 
        this.isCertificatesModal = false;
      this.showSignPepModal = true;
    },
    closePepModal(signed = false, back = false) {
      this.showSignPepModal = false;
      if(back) 
        this.isCertificatesModal = true;
      if(signed)
        this.getDocuments();
      if(!back && !this.isExternal) 
        utils.removeModalOpenClass();
    },
    openSignHistory() {
      this.$refs.signHistory.show();
    },
    hideSignHistory() {
      this.$refs.signHistory.hide();
    },
  },
  computed: {
    currentSigner() {
      const current = this.document.signers.find(signer => signer.workerId == (this.currentStudent.ID || this.worker.id))

      return current || ''
    },
    signedDate() {
      return this.isSigned && this.currentSigner && this.currentSigner.dsProcessedAt || ''
    },
    isSignedOnPaper() {
      return this.document.processed && !this.document.dsProcessed
    },
    isSigned() {
      return this.currentSigner && this.currentSigner.dsProcessedAt
    },
    signType() {
      const signType = this.currentSigner && this.currentSigner.sign && this.currentSigner.sign.signatureType;
      let signTypeText;
      switch (signType) {
        case "ds":
          signTypeText = this.$t('sign.ds');
          break;
        case "pep": 
          signTypeText = this.$t('sign.pep');
          break;
        default:
          signTypeText = ''
      }
      return signTypeText;
    },
    canBeDs() {
      if(Object.getOwnPropertyDescriptor(this.signRules, this.document.type) === undefined) {
        return false
      }

      let allow = false
      if(this.document.isIAmResponsible) {
        allow = this.signRules[this.document.type].UF_SIGN_NEP_RESP || this.signRules[this.document.type].UF_SIGN_KEP_RESP || this.signRules[this.document.type].UF_SIGN_PEP_RESP
      } else {
        allow = this.signRules[this.document.type].UF_SIGN_NEP || this.signRules[this.document.type].UF_SIGN_KEP || this.signRules[this.document.type].UF_SIGN_PEP
      }

      if (allow === false || this.signRules[this.document.type].UF_SIGN_ONLY_DOC_DATE === false) {
          return allow
      }

      if (this.documentDate !== this.today()) {
          this.setTooltipMessage(this.$t('sign.sing_allowed_only_date', {date: this.documentDate}));
          return false
      }
      return true
    },
    canSign() {
      return this.hasAccessToSign;
    },
    ...mapState([
      'hasAccessToSign',
      'currentStudent',
      'worker',
    ])
  },
}
